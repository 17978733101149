/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<LoggedInUserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCookieExpiration(options: IRequestOptions = {}): Promise<DoubleApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/cookie-expiration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateTimezone(
    params: {
      /** requestBody */
      body?: SetUserTimezoneCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/update-timezone';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AchBatchService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchBatchListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-batches/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchBatchDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-batches/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditAchBatchCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-batches/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateAchBatchCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-batches';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAchTransactionOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-batches/ach-transaction-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AchTransactionService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AchTransactionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditAchTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateAchTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static void(
    params: {
      /** requestBody */
      body?: VoidAchTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/void';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/vendor-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBenefitTransactionOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/benefit-transaction-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAchBatchOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ach-transactions/ach-batch-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthorizationService {
  /**
   *
   */
  static authorize(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/authorize';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authorize1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/authorize';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exchange(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/change-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: MobileForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MobileForgotPasswordResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<LoggedInUserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/connect/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BenefitsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetBenefitsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BenefitListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateBenefitCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static redeem(
    params: {
      /** requestBody */
      body?: TransactionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/redeem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaFundingOptions(
    params: {
      /**  */
      recipientId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/ServiceAreaFunding/{recipientId}';
      url = url.replace('{recipientId}', params['recipientId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPrintableBenefit(
    params: {
      /**  */
      benefitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BenefitPrintDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/print-benefit/{benefitId}';
      url = url.replace('{benefitId}', params['benefitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBenefitQrCode(
    params: {
      /**  */
      benefitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/qr/{benefitId}';
      url = url.replace('{benefitId}', params['benefitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRecipientEmail(
    params: {
      /**  */
      benefitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BenefitEmailDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/get-recipient-email/{benefitId}';
      url = url.replace('{benefitId}', params['benefitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendBenefitEmail(
    params: {
      /**  */
      benefitId: number;
      /** requestBody */
      body?: SendBenefitEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/email-benefit/{benefitId}';
      url = url.replace('{benefitId}', params['benefitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivateBenefit(
    params: {
      /**  */
      benefitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/benefits/deactivate-benefit/{benefitId}';
      url = url.replace('{benefitId}', params['benefitId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ChangeLogService {
  /**
   *
   */
  static getLogs(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityChangeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/changelogs/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFiltered(
    params: {
      /** requestBody */
      body?: GetIssuancePeriodSummaryQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssuancePeriodDataFetchDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/filter';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HybridCodesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HybridCodeBatchListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HybridCodeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateHybridCodesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportDataSetCsv(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes/export-csv/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes/program-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivate(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/hybrid-codes/deactivate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IssuancePeriodsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetIssuancePeriodsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssuancePeriodListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssuancePeriodDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: IssuancePeriodDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: IssuancePeriodDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/programOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramType(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/programType/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIssuancePeriodOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/issuance-periods/IssuancePeriodOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LocalStaffUsersService {
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: GetLocalStaffUsersTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalStaffUserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateUser(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateLocalStaffUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createUser(
    params: {
      /** requestBody */
      body?: CreateLocalStaffUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /**  */
      id?: number;
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChangeLocalStaffPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/change-password/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStateOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/stateOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/serviceAreaOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLockedStatus(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/local-staff-users/lock/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MarketManagerUsersService {
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: GetMarketManagerUsersTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketManagerUserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateUser(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateMarketManagerUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createUser(
    params: {
      /** requestBody */
      body?: CreateMarketManagerUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /**  */
      id?: number;
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/send-confirm-email';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChangeMarketManagerPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/change-password/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/role-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStateOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/state-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLockedStatus(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/market-manager-users/lock/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileAchTransactionService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetVendorAchTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorAchTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/ach-transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileBenefitsService {
  /**
   *
   */
  static getBenefitsByCode(
    params: {
      /**  */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetBenefitsByCodeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/code/{code}';
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBalanceByCode(
    params: {
      /**  */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BalanceResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/balance/{code}';
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validate(
    params: {
      /** requestBody */
      body?: ValidateBenefitTransactionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ValidateTransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/validate-for-transaction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static redeem(
    params: {
      /** requestBody */
      body?: TransactionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/redeem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static redeemVouchers(
    params: {
      /** requestBody */
      body?: TransactionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/redeem-vouchers';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static marketRedeem(
    params: {
      /** requestBody */
      body?: TransactionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/market-manager-redeem';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static marketRedeemVouchers(
    params: {
      /** requestBody */
      body?: TransactionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VoucherTransactionResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/benefits/market-manager-redeem-vouchers';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileConfigurationService {
  /**
   *
   */
  static checkForForcedUpdate(
    params: {
      /**  */
      version: string;
      /**  */
      platform: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile-configuration/{version}/{platform}';
      url = url.replace('{version}', params['version'] + '');
      url = url.replace('{platform}', params['platform'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileRecipientService {
  /**
   *
   */
  static getByUser(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/recipients';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPrograms(
    params: {
      /**  */
      recipientId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientProgramsResponseDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/recipients/programs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { recipientId: params['recipientId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileTransactionsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetTransactionsTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPending(
    params: {
      /** requestBody */
      body?: GetPendingTransactionsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PendingTransactionsResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/transactions/pending';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDailyTotal(
    params: {
      /** requestBody */
      body?: DailyTransactionsTotalRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DailyTransactionsTotalResponseDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/transactions/daily-total';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileUserService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static notificationSettings(
    params: {
      /** requestBody */
      body?: UserNotificationSettingsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/users/notification-settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileVendorService {
  /**
   *
   */
  static getByUser(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/vendors';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByName(
    params: {
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDetailsDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/vendors/by-name';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByNumber(
    params: {
      /**  */
      number?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDetailsDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/vendors/by-number';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { number: params['number'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAch(
    params: {
      /** requestBody */
      body?: UpdateVendorAchCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/mobile/vendors/ach';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PhysicalCheckService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetPhysicalCheckFileTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PhysicalCheckFileListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFileTable(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetPhysicalCheckDepositTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PhysicalCheckDepositListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upload(
    params: {
      /**  */
      physicalCheckFileCsv: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['physicalCheckFileCsv']) {
        if (Object.prototype.toString.call(params['physicalCheckFileCsv']) === '[object Array]') {
          for (const item of params['physicalCheckFileCsv']) {
            data.append('PhysicalCheckFileCsv', item as any);
          }
        } else {
          data.append('PhysicalCheckFileCsv', params['physicalCheckFileCsv'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editPhysicalCheck(
    params: {
      /**  */
      physicalCheckDepositId: number;
      /**  */
      vendorId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/select-vendor';

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['physicalCheckDepositId']) {
        if (Object.prototype.toString.call(params['physicalCheckDepositId']) === '[object Array]') {
          for (const item of params['physicalCheckDepositId']) {
            data.append('PhysicalCheckDepositId', item as any);
          }
        } else {
          data.append('PhysicalCheckDepositId', params['physicalCheckDepositId'] as any);
        }
      }

      if (params['vendorId']) {
        if (Object.prototype.toString.call(params['vendorId']) === '[object Array]') {
          for (const item of params['vendorId']) {
            data.append('VendorId', item as any);
          }
        } else {
          data.append('VendorId', params['vendorId'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static failureTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PhysicalCheckDepositImportFailureListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/import-failures';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static failuresCsv(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/failure-csv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteFailure(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/physical-checks/failure/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProgramsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editNoPhoto(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ProgramCreateEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createNoPhoto(
    params: {
      /** requestBody */
      body?: ProgramCreateEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStateOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/state-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramTypeOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/program-type-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogo(
    params: {
      /**  */
      programId: number;
      /**  */
      logoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/logo/{programId}/{logoGuid}';
      url = url.replace('{programId}', params['programId'] + '');
      url = url.replace('{logoGuid}', params['logoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RecipientsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetRecipientsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocalTable(
    params: {
      /** requestBody */
      body?: GetRecipientsByServiceAreaDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/local-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /**  */
      userName?: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientIdDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userName: params['userName'], name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditRecipientCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecipientEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkForExisting(
    params: {
      /**  */
      firstName?: string;
      /**  */
      lastName?: string;
      /**  */
      dateOfBirth?: string;
      /**  */
      stateId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExistingRecipientResultDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/check-for-existing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FirstName: params['firstName'],
        LastName: params['lastName'],
        DateOfBirth: params['dateOfBirth'],
        StateId: params['stateId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateRecipientCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static archive(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/archive/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/service-area-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/deactivate/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static issuedBenefitsSum(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/issued-benefits-sum/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static redeemedBenefitsSum(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/redeemed-benefits-sum/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWicCategoryOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/wic-category-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEthnicityOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/ethnicity-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRaceOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/race-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCountyOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/county-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkForAvailableBenefits(
    params: {
      /**  */
      recipientId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BenefitListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/check-for-available-benefits';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { RecipientId: params['recipientId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLockedStatus(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/lock/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHybridCodeOptions(
    params: {
      /** requestBody */
      body?: GetHybridCodesQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/hybrid-code-options';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hasHybridCodeAssigned(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/hybrid-code-assigned/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHybridCodesByRecipientId(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HybridCodeDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/hybrid-code-list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChangeRecipientPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/recipients/change-password/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReportsService {
  /**
   *
   */
  static getReports(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDefinitionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReport(
    params: {
      /**  */
      reportId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportDefinitionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/{reportId}';
      url = url.replace('{reportId}', params['reportId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReportDataPage(
    params: {
      /** requestBody */
      body?: GetReportDataPageQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportSetDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/dataset-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDataSetColumnFilterOptions(
    params: {
      /** requestBody */
      body?: ReportDataSetsGetReportDataSetColumnSelectOptionsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/dataset-select-column-options';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportDataSetCsv(
    params: {
      /** requestBody */
      body?: GetReportDefinitionDataSetExportCsvQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/dataset-table-export-csv';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wicFmnpReport(
    params: {
      /** requestBody */
      body?: GetWicFmnpReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WicFmnpReportDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/wic-fmnp-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSeniorFmnpReport(
    params: {
      /** requestBody */
      body?: GetSeniorFmnpReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeniorFmnpDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/senior-fmnp-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInternalIssuanceReport(
    params: {
      /** requestBody */
      body?: InternalIssuanceReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InternalIssuanceReportDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/internal-issuance-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorReport(
    params: {
      /** requestBody */
      body?: VendorReportQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorReportDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/vendor-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportRecipientDataToCsv(
    params: {
      /** requestBody */
      body?: RecipientReportExportCsv;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reports/recipient-csv-report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceAreaService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetServiceAreasTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceAreaListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceAreaDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ServiceAreaDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ServiceAreaDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-areas/ServiceAreaOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceAreaFundingsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetServiceAreaFundingsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceAreaFundingListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRunningTotal(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceAreaFundingEntryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateServiceAreaFundingCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEntry(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateServiceAreaFundingEntryCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings/create-entry/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings/serviceAreaOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIssuancePeriodOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/service-area-fundings/issuancePeriodOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingGetEntityChangeLogsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityChangeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/change-table/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatesService {
  /**
   *
   */
  static getTimeZoneOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/time-zones';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadCsv(
    params: {
      /**  */
      file: any;
      /**  */
      stateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/csv';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      if (params['stateId']) {
        if (Object.prototype.toString.call(params['stateId']) === '[object Array]') {
          for (const item of params['stateId']) {
            data.append('StateId', item as any);
          }
        } else {
          data.append('StateId', params['stateId'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StateListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: StateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: StateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/states';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TransactionsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetTransactionsTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refundOrVoid(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/refund-or-void/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransactionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/service-area-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/program-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/vendor-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBenefitOptions(
    params: {
      /**  */
      searchTerm?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/transactions/benefit-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { searchTerm: params['searchTerm'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UploadService {
  /**
   *
   */
  static importHybridCheckRecipients(
    params: {
      /**  */
      hybridCheckRecipientsCsv: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-check-recipients';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['hybridCheckRecipientsCsv']) {
        if (Object.prototype.toString.call(params['hybridCheckRecipientsCsv']) === '[object Array]') {
          for (const item of params['hybridCheckRecipientsCsv']) {
            data.append('HybridCheckRecipientsCsv', item as any);
          }
        } else {
          data.append('HybridCheckRecipientsCsv', params['hybridCheckRecipientsCsv'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkImportFailureTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HybridCheckRecipientImportFailureListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-check/import-failures';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hybridCheckFailuresCsv(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-check/failure-csv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteCheckImportFailure(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-check/failure/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importHybridCardRecipients(
    params: {
      /**  */
      hybridCardRecipientsCsv: any;
      /**  */
      issuancePeriodId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-card-recipients';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['hybridCardRecipientsCsv']) {
        if (Object.prototype.toString.call(params['hybridCardRecipientsCsv']) === '[object Array]') {
          for (const item of params['hybridCardRecipientsCsv']) {
            data.append('HybridCardRecipientsCsv', item as any);
          }
        } else {
          data.append('HybridCardRecipientsCsv', params['hybridCardRecipientsCsv'] as any);
        }
      }

      if (params['issuancePeriodId']) {
        if (Object.prototype.toString.call(params['issuancePeriodId']) === '[object Array]') {
          for (const item of params['issuancePeriodId']) {
            data.append('IssuancePeriodId', item as any);
          }
        } else {
          data.append('IssuancePeriodId', params['issuancePeriodId'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cardImportFailureTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HybridCardRecipientImportFailureListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-card/import-failures';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hybridCardFailuresCsv(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-card/failure-csv';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteCardImportFailure(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/upload/hybrid-card/failure/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getProfilePhoto(
    params: {
      /**  */
      userId: number;
      /**  */
      profilePhotoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{userId}/profilePhoto/{profilePhotoGuid}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{profilePhotoGuid}', params['profilePhotoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUserNoPhoto(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStateOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/stateOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getServiceAreaOptions(
    params: {
      /**  */
      stateId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/serviceAreaOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { stateId: params['stateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorOptions(
    params: {
      /**  */
      stateId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/vendorOptons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { stateId: params['stateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportToCsv(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileContentResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportAllToCsv(
    params: {
      /** requestBody */
      body?: TakeAllDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileContentResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/export-all';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLogs(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UserGetEntityChangeLogsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityChangeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/change-table/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLockedStatus(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/lock/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VendorsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetVendorsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: VendorEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEditDto(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/edit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateVendorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorUser(
    params: {
      /**  */
      id: number;
      /**  */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorUserEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/{id}/users/{userId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userEdit(
    params: {
      /**  */
      id: number;
      /**  */
      userId: number;
      /** requestBody */
      body?: VendorUserEditDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/{id}/users/{userId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static archive(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/archive/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransactionsTable(
    params: {
      /** requestBody */
      body?: GetVendorTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BenefitTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/transactions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendorAchTransactionsTable(
    params: {
      /** requestBody */
      body?: GetVendorAchTransactionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VendorAchTransactionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/ach-transactions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: GetVendorUsersDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/users-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /**  */
      id?: number;
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVendors(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkForExistingVendor(
    params: {
      /**  */
      userName?: string;
      /**  */
      stateId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExistingVendorStateResultDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/check-for-existing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { UserName: params['userName'], StateId: params['stateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createVendorState(
    params: {
      /** requestBody */
      body?: CreateVendorStateCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/vendors/createVendorState';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  isAlreadyConfirmed?: boolean;

  /**  */
  canLoginToPortal?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AchBatchDto {
  /**  */
  id?: number;

  /**  */
  occurred?: string;

  /**  */
  achTransactions?: number[];
}

export interface AchBatchDtoApiResult {
  /**  */
  result?: AchBatchDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AchBatchListItemDto {
  /**  */
  id?: number;

  /**  */
  occurred?: string;
}

export interface AchBatchListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AchBatchListItemDto[];
}

export interface AchBatchListItemDtoDataTablePageApiResult {
  /**  */
  result?: AchBatchListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AchTransactionDto {
  /**  */
  id?: number;

  /**  */
  vendorId?: number;

  /**  */
  achBatchId?: number;

  /**  */
  occurred?: string;

  /**  */
  amount?: number;

  /**  */
  benefitTransactions?: number[];
}

export interface AchTransactionDtoApiResult {
  /**  */
  result?: AchTransactionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AchTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  achBatchId?: number;

  /**  */
  vendorName?: string;

  /**  */
  occurred?: string;

  /**  */
  amount?: number;

  /**  */
  referenceNumber?: string;
}

export interface AchTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AchTransactionListItemDto[];
}

export interface AchTransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: AchTransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;

  /**  */
  parameters?: object;

  /**  */
  isPersistent?: boolean;

  /**  */
  redirectUri?: string;

  /**  */
  issuedUtc?: string;

  /**  */
  expiresUtc?: string;

  /**  */
  allowRefresh?: boolean;
}

export interface BalanceResponseDto {
  /**  */
  programType?: string;

  /**  */
  totalBalance?: number;

  /**  */
  balancePerVoucher?: number;

  /**  */
  vouchersRemaining?: number;

  /**  */
  expirationDate?: string;
}

export interface BalanceResponseDtoApiResult {
  /**  */
  result?: BalanceResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BenefitEmailDto {
  /**  */
  id?: number;

  /**  */
  email?: string;
}

export interface BenefitEmailDtoApiResult {
  /**  */
  result?: BenefitEmailDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BenefitListItemDto {
  /**  */
  id?: number;

  /**  */
  amount?: number;

  /**  */
  balance?: number;

  /**  */
  startDate?: string;

  /**  */
  expirationDate?: string;

  /**  */
  benefitCode?: string;

  /**  */
  isDeactivated?: boolean;

  /**  */
  isExpired?: boolean;

  /**  */
  programName?: string;

  /**  */
  programType?: string;

  /**  */
  issuancePeriodName?: string;

  /**  */
  serviceAreaName?: string;

  /**  */
  initials?: string;
}

export interface BenefitListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: BenefitListItemDto[];
}

export interface BenefitListItemDtoDataTablePageApiResult {
  /**  */
  result?: BenefitListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BenefitListItemDtoListApiResult {
  /**  */
  result?: BenefitListItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BenefitPrintDto {
  /**  */
  id?: number;

  /**  */
  benefitCode?: string;

  /**  */
  amount?: number;

  /**  */
  startDate?: string;

  /**  */
  expirationDate?: string;

  /**  */
  recipient?: RecipientDto;

  /**  */
  program?: ProgramPrintDto;
}

export interface BenefitPrintDtoApiResult {
  /**  */
  result?: BenefitPrintDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BenefitResponseDto {
  /**  */
  id?: number;

  /**  */
  amount?: number;

  /**  */
  balance?: number;

  /**  */
  benefitCode?: string;

  /**  */
  startDate?: string;

  /**  */
  expirationDate?: string;
}

export interface BenefitTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  type?: string;

  /**  */
  transactionId?: number;

  /**  */
  occurred?: string;

  /**  */
  amount?: number;

  /**  */
  recipient?: string;
}

export interface BenefitTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: BenefitTransactionListItemDto[];
}

export interface BenefitTransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: BenefitTransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BooleanApiResult {
  /**  */
  result?: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CellComponentAttribute {
  /**  */
  name?: string;

  /**  */
  typeId?: any | null;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangeLocalStaffPasswordCommand {
  /**  */
  id?: number;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ChangeMarketManagerPasswordCommand {
  /**  */
  id?: number;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface ChangeRecipientPasswordCommand {
  /**  */
  id?: number;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ChildIssuancePeriodDataFetchDto {
  /**  */
  program?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  totalFundingAmount?: number;

  /**  */
  issuedFunds?: number;

  /**  */
  benefitsAvailableForIssuance?: number;

  /**  */
  numberOfRecipients?: number;

  /**  */
  redeemedBenefits?: number;

  /**  */
  unredeemedBenefits?: number;

  /**  */
  issuancePeriodId?: number;

  /**  */
  issuancePeriodName?: string;

  /**  */
  serviceAreaOptions?: OptionItemDto[];
}

export interface ColumnDefinition {
  /**  */
  accessor?: string;

  /**  */
  header?: string;

  /**  */
  sortable?: boolean;

  /**  */
  filterable?: boolean;

  /**  */
  type?: string;

  /**  */
  componentAttribute?: CellComponentAttribute;
}

export interface ContactInformationDto {
  /**  */
  id?: number;

  /**  */
  description?: string;

  /**  */
  value?: string;

  /**  */
  isDeleted?: boolean;
}

export interface CountyDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface CreateAchBatchCommand {
  /**  */
  id?: number;

  /**  */
  occurred?: string;

  /**  */
  achTransactions?: number[];
}

export interface CreateAchTransactionCommand {
  /**  */
  vendorId?: number;

  /**  */
  achBatchId?: number;

  /**  */
  amount?: number;

  /**  */
  occurred?: string;

  /**  */
  benefitTransactions?: number[];
}

export interface CreateBenefitCommand {
  /**  */
  amount?: number;

  /**  */
  startDate?: string;

  /**  */
  expirationDate?: string;

  /**  */
  serviceAreaFundingId?: number;

  /**  */
  recipientId?: number;

  /**  */
  numberOfBenefits?: number;

  /**  */
  initials?: string;
}

export interface CreateHybridCodesCommand {
  /**  */
  programId?: number;

  /**  */
  amount?: number;
}

export interface CreateLocalStaffUserCommand {
  /**  */
  username?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  stateId?: number;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;

  /**  */
  recipientId?: number;
}

export interface CreateMarketManagerUserCommand {
  /**  */
  username?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  stateId?: number;
}

export interface CreateRecipientCommand {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  userName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  wicCategory?: string;

  /**  */
  ethnicity?: string;

  /**  */
  race?: string;

  /**  */
  otherRace?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  serviceAreaId?: number;

  /**  */
  countyId?: number;

  /**  */
  nonDuplicateVerified?: boolean;

  /**  */
  hybridCodes?: number[];

  /**  */
  passwordRequired?: boolean;

  /**  */
  householdMembers?: HouseholdMemberDto[];

  /**  */
  stateId?: number;
}

export interface CreateServiceAreaFundingCommand {
  /**  */
  amount?: number;

  /**  */
  serviceAreaId?: number;

  /**  */
  issuancePeriodId?: number;
}

export interface CreateServiceAreaFundingEntryCommand {
  /**  */
  serviceAreaFundingId?: number;

  /**  */
  amount?: number;
}

export interface CreateUserCommand {
  /**  */
  notificationsEnabled?: boolean;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  username?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  stateId?: number;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;

  /**  */
  recipientId?: number;

  /**  */
  passwordRequired?: boolean;

  /**  */
  suppressConfirmationEmail?: boolean;
}

export interface CreateVendorCommand {
  /**  */
  name?: string;

  /**  */
  vendorNumber?: string;

  /**  */
  routingNumber?: string;

  /**  */
  accountNumber?: string;

  /**  */
  accountType?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  isArchived?: boolean;

  /**  */
  stateId?: number;

  /**  */
  nonDuplicateVerified?: boolean;
}

export interface CreateVendorStateCommand {
  /**  */
  userName?: string;

  /**  */
  vendorNumber?: string;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface DailyTransactionsTotalRequestDto {
  /**  */
  vendorId?: number;
}

export interface DailyTransactionsTotalResponseDto {
  /**  */
  count?: number;

  /**  */
  total?: number;
}

export interface DailyTransactionsTotalResponseDtoApiResult {
  /**  */
  result?: DailyTransactionsTotalResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DataSetColumnFilter {
  /**  */
  label?: string;

  /**  */
  propertyName?: string;

  /**  */
  searchTerm?: string;

  /**  */
  type?: string;

  /**  */
  operationalSearchTerm?: string;

  /**  */
  filterType?: FilterType;
}

export interface DataSetColumnSort {
  /**  */
  label?: string;

  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DecimalApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DoubleApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EditAchBatchCommand {
  /**  */
  id?: number;

  /**  */
  occurred?: string;

  /**  */
  achTransactions?: number[];
}

export interface EditAchTransactionCommand {
  /**  */
  id?: number;

  /**  */
  vendorId?: number;

  /**  */
  achBatchId?: number;

  /**  */
  amount?: number;

  /**  */
  occurred?: string;

  /**  */
  benefitTransactions?: number[];
}

export interface EditRecipientCommand {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  userName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  wicCategory?: string;

  /**  */
  ethnicity?: string;

  /**  */
  race?: string;

  /**  */
  otherRace?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  serviceAreaId?: number;

  /**  */
  countyId?: number;

  /**  */
  serviceAreaChangeConfirmed?: boolean;

  /**  */
  hybridCodes?: number[];

  /**  */
  householdMembers?: HouseholdMemberDto[];
}

export interface EntityChangeListItemDto {
  /**  */
  id?: number;

  /**  */
  changeLogAction?: ChangeLogAction;

  /**  */
  entityTypeName?: string;

  /**  */
  entityId?: number;

  /**  */
  recordTypeName?: string;

  /**  */
  recordPropertyName?: string;

  /**  */
  oldValue?: string;

  /**  */
  newValue?: string;

  /**  */
  entityChangeTransaction?: EntityChangeTransactionListItemDto;
}

export interface EntityChangeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: EntityChangeListItemDto[];
}

export interface EntityChangeListItemDtoDataTablePageApiResult {
  /**  */
  result?: EntityChangeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EntityChangeTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  timestamp?: string;

  /**  */
  principal?: string;

  /**  */
  originAddress?: string;

  /**  */
  destinationAddress?: string;

  /**  */
  requestContext?: string;
}

export interface ExistingRecipientResultDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  recipientIdentifier?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isArchived?: boolean;

  /**  */
  serviceArea?: ServiceAreaDto;

  /**  */
  benefits?: BenefitListItemDto[];
}

export interface ExistingRecipientResultDtoListApiResult {
  /**  */
  result?: ExistingRecipientResultDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ExistingVendorStateResultDto {
  /**  */
  id?: number;

  /**  */
  vendorId?: number;

  /**  */
  username?: string;

  /**  */
  vendorNumber?: string;

  /**  */
  stateId?: number;

  /**  */
  stateName?: string;

  /**  */
  isArchived?: boolean;
}

export interface ExistingVendorStateResultDtoListApiResult {
  /**  */
  result?: ExistingVendorStateResultDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileContentResultApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileResourceDto {
  /**  */
  id?: number;

  /**  */
  guid?: string;

  /**  */
  name?: string;

  /**  */
  type?: string;
}

export interface ForgotPasswordCommand {
  /**  */
  username?: string;
}

export interface GetBenefitsByCodeDto {
  /**  */
  programType?: string;

  /**  */
  isVoucherProgram?: boolean;

  /**  */
  benefits?: BenefitResponseDto[];
}

export interface GetBenefitsByCodeDtoApiResult {
  /**  */
  result?: GetBenefitsByCodeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface GetBenefitsDataTableRequest {
  /**  */
  recipientId?: number;

  /**  */
  includeActiveBenefitsOnly?: boolean;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetHybridCodesQuery {
  /**  */
  recipientId?: number;

  /**  */
  searchTerm?: string;

  /**  */
  values?: number[];
}

export interface GetIssuancePeriodSummaryQuery {
  /**  */
  serviceAreaIds?: number[];

  /**  */
  issuancePeriodId?: number;
}

export interface GetIssuancePeriodsDataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetLocalStaffUsersTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetMarketManagerUsersTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetPendingTransactionsQuery {
  /**  */
  stateId?: number;

  /**  */
  vendorId?: number;
}

export interface GetPhysicalCheckDepositTableRequest {
  /**  */
  benefitTransactionId?: number;

  /**  */
  physicalCheckFileId?: number;

  /**  */
  transactionId?: number;

  /**  */
  amount?: number;

  /**  */
  dateUtc?: string;

  /**  */
  recipientId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetPhysicalCheckFileTableRequest {
  /**  */
  fileName?: string;

  /**  */
  createdDateUtc?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetRecipientsByServiceAreaDataTableRequest {
  /**  */
  showArchived?: boolean;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetRecipientsDataTableRequest {
  /**  */
  showArchived?: boolean;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetReportDataPageQuery {
  /**  */
  sorts?: DataSetColumnSort[];

  /**  */
  filters?: DataSetColumnFilter[];

  /**  */
  take?: number;

  /**  */
  reportDefinitionId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetReportDefinitionDataSetExportCsvQuery {
  /**  */
  take?: number;

  /**  */
  sorts?: DataSetColumnSort[];

  /**  */
  filters?: DataSetColumnFilter[];

  /**  */
  reportDefinitionId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetSeniorFmnpReportQuery {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  issuancePeriodId?: number;

  /**  */
  serviceAreaId?: number;
}

export interface GetServiceAreaFundingsDataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetServiceAreasTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetTransactionsTableRequest {
  /**  */
  programId?: number;

  /**  */
  vendorId?: number;

  /**  */
  benefitId?: number;

  /**  */
  serviceAreaId?: number;

  /**  */
  transactionType?: string;

  /**  */
  recipientIdentifier?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  serviceAreaIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetVendorAchTransactionsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetVendorTransactionsDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetVendorUsersDataTableRequest {
  /**  */
  vendorId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetVendorsDataTableRequest {
  /**  */
  showArchived?: boolean;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetWicFmnpReportQuery {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  issuancePeriodId?: number;

  /**  */
  serviceAreaId?: number;
}

export interface HouseholdMemberDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  wicCategory?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  recipientId?: number;

  /**  */
  isDeleted?: boolean;
}

export interface HybridCardRecipientImportFailureListItemDto {
  /**  */
  id?: number;

  /**  */
  failureMessage?: string;

  /**  */
  countyServiceArea?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  ethnicity?: string;

  /**  */
  race?: string;

  /**  */
  hybridCode?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;
}

export interface HybridCardRecipientImportFailureListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: HybridCardRecipientImportFailureListItemDto[];
}

export interface HybridCardRecipientImportFailureListItemDtoDataTablePageApiResult {
  /**  */
  result?: HybridCardRecipientImportFailureListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface HybridCheckRecipientImportFailureListItemDto {
  /**  */
  id?: number;

  /**  */
  failureMessage?: string;

  /**  */
  hybridCode?: string;

  /**  */
  firstCheckNumber?: string;

  /**  */
  lastCheckNumber?: string;

  /**  */
  countyAgencyName?: string;

  /**  */
  count?: number;

  /**  */
  allCheckNumbers?: string;

  /**  */
  checkNumberRange?: string;

  /**  */
  recipientName?: string;
}

export interface HybridCheckRecipientImportFailureListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: HybridCheckRecipientImportFailureListItemDto[];
}

export interface HybridCheckRecipientImportFailureListItemDtoDataTablePageApiResult {
  /**  */
  result?: HybridCheckRecipientImportFailureListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface HybridCodeBatchListItemDto {
  /**  */
  id?: number;

  /**  */
  codeCount?: number;

  /**  */
  usedCodes?: number;

  /**  */
  programStateName?: string;

  /**  */
  programName?: string;

  /**  */
  createdDateUtc?: string;
}

export interface HybridCodeBatchListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: HybridCodeBatchListItemDto[];
}

export interface HybridCodeBatchListItemDtoDataTablePageApiResult {
  /**  */
  result?: HybridCodeBatchListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface HybridCodeDto {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  programId?: number;

  /**  */
  programName?: string;

  /**  */
  isDeactivated?: boolean;
}

export interface HybridCodeDtoApiResult {
  /**  */
  result?: HybridCodeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface HybridCodeDtoListApiResult {
  /**  */
  result?: HybridCodeDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Int32NullableApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface InternalIssuanceReportDto {
  /**  */
  programName?: string;

  /**  */
  issuedBenefitsCount?: number;
}

export interface InternalIssuanceReportDtoListApiResult {
  /**  */
  result?: InternalIssuanceReportDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface InternalIssuanceReportQuery {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface IssuancePeriodDataFetchDto {
  /**  */
  id?: number;

  /**  */
  childData?: ChildIssuancePeriodDataFetchDto[];

  /**  */
  dashboardDataType?: DashboardDataType;
}

export interface IssuancePeriodDataFetchDtoApiResult {
  /**  */
  result?: IssuancePeriodDataFetchDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IssuancePeriodDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  programId?: number;

  /**  */
  availableFunds?: number;

  /**  */
  numberOfBenefitVouchers?: number;

  /**  */
  amountPerBenefitVoucher?: number;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  benefitAmountPerRecipient?: number;

  /**  */
  stateId?: number;

  /**  */
  stateName?: string;
}

export interface IssuancePeriodDtoApiResult {
  /**  */
  result?: IssuancePeriodDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IssuancePeriodListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  programName?: string;

  /**  */
  availableFunds?: number;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface IssuancePeriodListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: IssuancePeriodListItemDto[];
}

export interface IssuancePeriodListItemDtoDataTablePageApiResult {
  /**  */
  result?: IssuancePeriodListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocalStaffUserListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  serviceAreaNames?: string;

  /**  */
  isLockedOut?: boolean;
}

export interface LocalStaffUserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LocalStaffUserListItemDto[];
}

export interface LocalStaffUserListItemDtoDataTablePageApiResult {
  /**  */
  result?: LocalStaffUserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LoggedInUserDto {
  /**  */
  roleNames?: string[];

  /**  */
  isStateStaff?: boolean;

  /**  */
  isLocalStaff?: boolean;

  /**  */
  isAdmin?: boolean;

  /**  */
  isVendor?: boolean;

  /**  */
  stateEarliestTimeZone?: string;

  /**  */
  stateLatestTimeZone?: string;

  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  notificationsEnabled?: boolean;

  /**  */
  contactInformation?: ContactInformationDto[];

  /**  */
  profilePhotoGuid?: string;

  /**  */
  userRoles?: number[];

  /**  */
  stateId?: number;

  /**  */
  stateName?: string;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;

  /**  */
  recipientId?: number;

  /**  */
  isLockedOut?: boolean;
}

export interface LoggedInUserDtoApiResult {
  /**  */
  result?: LoggedInUserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface MarketManagerUserListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  isLockedOut?: boolean;
}

export interface MarketManagerUserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: MarketManagerUserListItemDto[];
}

export interface MarketManagerUserListItemDtoDataTablePageApiResult {
  /**  */
  result?: MarketManagerUserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface MobileForgotPasswordCommand {
  /**  */
  username?: string;
}

export interface MobileForgotPasswordResponseDto {
  /**  */
  success?: boolean;

  /**  */
  programs?: ProgramDto[];
}

export interface MobileForgotPasswordResponseDtoApiResult {
  /**  */
  result?: MobileForgotPasswordResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PasswordLoginCommand {
  /**  */
  username?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface PendingTransactionsResponseDto {
  /**  */
  balance?: number;

  /**  */
  benefitTransactions?: BenefitTransactionListItemDto[];
}

export interface PendingTransactionsResponseDtoApiResult {
  /**  */
  result?: PendingTransactionsResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PhysicalCheckDepositImportFailureListItemDto {
  /**  */
  id?: number;

  /**  */
  failureMessage?: string;

  /**  */
  accountNumber?: string;

  /**  */
  transactionDate?: string;

  /**  */
  checkNumber?: string;

  /**  */
  participantId?: string;

  /**  */
  hybridCode?: string;
}

export interface PhysicalCheckDepositImportFailureListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PhysicalCheckDepositImportFailureListItemDto[];
}

export interface PhysicalCheckDepositImportFailureListItemDtoDataTablePageApiResult {
  /**  */
  result?: PhysicalCheckDepositImportFailureListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PhysicalCheckDepositListItemDto {
  /**  */
  id?: number;

  /**  */
  benefitTransactionId?: number;

  /**  */
  amount?: number;

  /**  */
  dateUtc?: string;

  /**  */
  recipientId?: number;

  /**  */
  vendorId?: number;

  /**  */
  stateId?: number;

  /**  */
  checkNumber?: string;
}

export interface PhysicalCheckDepositListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PhysicalCheckDepositListItemDto[];
}

export interface PhysicalCheckDepositListItemDtoDataTablePageApiResult {
  /**  */
  result?: PhysicalCheckDepositListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PhysicalCheckFileListItemDto {
  /**  */
  id?: number;

  /**  */
  fileName?: string;

  /**  */
  createdDateUtc?: string;

  /**  */
  transactionsWithoutVendors?: number;

  /**  */
  transactionCount?: number;
}

export interface PhysicalCheckFileListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PhysicalCheckFileListItemDto[];
}

export interface PhysicalCheckFileListItemDtoDataTablePageApiResult {
  /**  */
  result?: PhysicalCheckFileListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ProgramCreateEditDto {
  /**  */
  logo?: any;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateId?: number;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  programType?: string;

  /**  */
  eCheckMerchantApiKey?: string;

  /**  */
  marketManagerTransaction?: boolean;
}

export interface ProgramDetailsDto {
  /**  */
  logo?: FileResourceDto;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateId?: number;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  programType?: string;

  /**  */
  eCheckMerchantApiKey?: string;

  /**  */
  marketManagerTransaction?: boolean;
}

export interface ProgramDetailsDtoApiResult {
  /**  */
  result?: ProgramDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ProgramDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateId?: number;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  programType?: string;

  /**  */
  eCheckMerchantApiKey?: string;

  /**  */
  marketManagerTransaction?: boolean;
}

export interface ProgramListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateName?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  programType?: string;

  /**  */
  marketManagerTransaction?: boolean;
}

export interface ProgramListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ProgramListItemDto[];
}

export interface ProgramListItemDtoDataTablePageApiResult {
  /**  */
  result?: ProgramListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ProgramPrintDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateName?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  logo?: FileResourceDto;
}

export interface RecipientDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  wicCategory?: string;

  /**  */
  ethnicity?: string;

  /**  */
  race?: string;

  /**  */
  otherRace?: string;

  /**  */
  isArchived?: boolean;

  /**  */
  isLockedOut?: boolean;

  /**  */
  dateOfBirth?: string;

  /**  */
  recipientIdentifier?: string;

  /**  */
  serviceAreaId?: number;

  /**  */
  serviceArea?: ServiceAreaDto;

  /**  */
  countyId?: number;

  /**  */
  county?: CountyDto;

  /**  */
  household?: boolean;

  /**  */
  householdMembers?: HouseholdMemberDto[];

  /**  */
  hybridCodes?: number[];
}

export interface RecipientDtoApiResult {
  /**  */
  result?: RecipientDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RecipientEditDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  wicCategory?: string;

  /**  */
  ethnicity?: string;

  /**  */
  race?: string;

  /**  */
  otherRace?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  serviceAreaId?: number;

  /**  */
  countyId?: number;

  /**  */
  hybridCodes?: number[];

  /**  */
  passwordNotRequired?: boolean;

  /**  */
  household?: boolean;

  /**  */
  householdMembers?: HouseholdMemberDto[];
}

export interface RecipientEditDtoApiResult {
  /**  */
  result?: RecipientEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RecipientIdDto {
  /**  */
  id?: number;
}

export interface RecipientIdDtoApiResult {
  /**  */
  result?: RecipientIdDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RecipientListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirth?: string;

  /**  */
  serviceAreaName?: string;

  /**  */
  countyName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  recipientIdentifier?: string;

  /**  */
  isArchived?: boolean;

  /**  */
  houseHoldMembers?: string[];

  /**  */
  searchableHouseHoldMembers?: SearchableHouseHoldMembers[];

  /**  */
  searchableHybridCodes?: SearchableHybridCode[];
}

export interface RecipientListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: RecipientListItemDto[];
}

export interface RecipientListItemDtoDataTablePageApiResult {
  /**  */
  result?: RecipientListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RecipientProgramsResponseDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  state?: string;

  /**  */
  supportPhoneNumber?: string;

  /**  */
  supportEmailAddress?: string;

  /**  */
  issuanceStartDate?: string;

  /**  */
  issuanceEndDate?: string;

  /**  */
  benefit?: BenefitResponseDto;
}

export interface RecipientProgramsResponseDtoListApiResult {
  /**  */
  result?: RecipientProgramsResponseDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RecipientReportExportCsv {
  /**  */
  take?: number;

  /**  */
  issuancePeriodId?: number;

  /**  */
  reportDefinitionId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface RecipientResponseDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  emailAddress?: string;

  /**  */
  isArchived?: boolean;

  /**  */
  dateOfBirth?: string;

  /**  */
  recipientIdentifier?: string;
}

export interface RecipientResponseDtoApiResult {
  /**  */
  result?: RecipientResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RegisterUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface RelatedTransactionDto {
  /**  */
  id?: number;

  /**  */
  occurred?: string;

  /**  */
  type?: string;
}

export interface ReportDataSetsGetReportDataSetColumnSelectOptionsQuery {
  /**  */
  reportDataSet?: ReportDataSets;

  /**  */
  propertyName?: string;
}

export interface ReportDefinitionDto {
  /**  */
  name?: string;

  /**  */
  reportExportFileType?: string;

  /**  */
  columnSortDefaults?: DataSetColumnSort[];

  /**  */
  columnFilterDefaults?: DataSetColumnFilter[];

  /**  */
  reportDataSet?: ReportDataSets;
}

export interface ReportDefinitionDtoApiResult {
  /**  */
  result?: ReportDefinitionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ReportDefinitionListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface ReportDefinitionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ReportDefinitionListItemDto[];
}

export interface ReportDefinitionListItemDtoDataTablePageApiResult {
  /**  */
  result?: ReportDefinitionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ReportSetDataTablePage {
  /**  */
  columns?: ColumnDefinition[];

  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: any | null[];
}

export interface ReportSetDataTablePageApiResult {
  /**  */
  result?: ReportSetDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface SearchableHouseHoldMembers {
  /**  */
  name?: string;
}

export interface SearchableHybridCode {
  /**  */
  code?: string;
}

export interface SendBenefitEmailCommand {
  /**  */
  benefitId?: number;

  /**  */
  email?: string;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface SeniorFmnpDto {
  /**  */
  participantsSixtyOrOlder?: number;

  /**  */
  totalValueOfBenefitsIssued?: number;

  /**  */
  totalValueOfBenefitsRedeemed?: number;
}

export interface SeniorFmnpDtoApiResult {
  /**  */
  result?: SeniorFmnpDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ServiceAreaDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateId?: number;
}

export interface ServiceAreaDtoApiResult {
  /**  */
  result?: ServiceAreaDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ServiceAreaFundingEntryDto {
  /**  */
  id?: number;

  /**  */
  amount?: number;
}

export interface ServiceAreaFundingEntryDtoApiResult {
  /**  */
  result?: ServiceAreaFundingEntryDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ServiceAreaFundingListItemDto {
  /**  */
  id?: number;

  /**  */
  serviceAreaName?: string;

  /**  */
  issuancePeriodName?: string;

  /**  */
  balance?: number;
}

export interface ServiceAreaFundingListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ServiceAreaFundingListItemDto[];
}

export interface ServiceAreaFundingListItemDtoDataTablePageApiResult {
  /**  */
  result?: ServiceAreaFundingListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ServiceAreaListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  stateName?: string;
}

export interface ServiceAreaListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ServiceAreaListItemDto[];
}

export interface ServiceAreaListItemDtoDataTablePageApiResult {
  /**  */
  result?: ServiceAreaListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SetUserTimezoneCommand {
  /**  */
  reportedTimeZone?: string;
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingGetEntityChangeLogsDataTableRequest {
  /**  */
  id?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface StateDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  earliestTimeZone?: string;

  /**  */
  latestTimeZone?: string;
}

export interface StateDtoApiResult {
  /**  */
  result?: StateDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StateListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  earliestTimeZone?: string;

  /**  */
  latestTimeZone?: string;
}

export interface StateListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: StateListItemDto[];
}

export interface StateListItemDtoDataTablePageApiResult {
  /**  */
  result?: StateListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  result?: StringOptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface TakeAllDataTableRequest {
  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface TransactionDto {
  /**  */
  id?: number;

  /**  */
  transactionId?: number;

  /**  */
  occurred?: string;

  /**  */
  recipientIdentifier?: string;

  /**  */
  vendorName?: string;

  /**  */
  amount?: number;

  /**  */
  type?: string;

  /**  */
  stateId?: number;

  /**  */
  isTransferred?: boolean;

  /**  */
  isPhysicallyDeposited?: boolean;

  /**  */
  relatedTransactions?: RelatedTransactionDto[];

  /**  */
  latestTransactionType?: string;
}

export interface TransactionDtoApiResult {
  /**  */
  result?: TransactionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface TransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  transactionId?: number;

  /**  */
  dateUtc?: string;

  /**  */
  type?: string;

  /**  */
  amount?: number;

  /**  */
  vendorName?: string;

  /**  */
  recipientId?: number;

  /**  */
  recipientIdentifier?: string;

  /**  */
  achTransferred?: boolean;

  /**  */
  physicallyDeposited?: boolean;
}

export interface TransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: TransactionListItemDto[];
}

export interface TransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: TransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface TransactionRequestDto {
  /**  */
  vendorId?: number;

  /**  */
  code?: string;

  /**  */
  amount?: number;

  /**  */
  numberOfVouchersToRedeem?: number;
}

export interface TransactionResponseDto {
  /**  */
  transactionId?: number;

  /**  */
  amount?: number;

  /**  */
  status?: string;

  /**  */
  statusMessage?: string;
}

export interface TransactionResponseDtoApiResult {
  /**  */
  result?: TransactionResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UpdateLocalStaffUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhoto?: any;

  /**  */
  contactInformation?: ContactInformationDto[];

  /**  */
  stateId?: number;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;
}

export interface UpdateMarketManagerUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhoto?: any;

  /**  */
  contactInformation?: ContactInformationDto[];

  /**  */
  stateId?: number;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  notificationsEnabled?: boolean;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhoto?: any;

  /**  */
  stateId?: number;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;

  /**  */
  recipientId?: number;
}

export interface UpdateVendorAchCommand {
  /**  */
  vendorId?: number;

  /**  */
  accountType?: string;

  /**  */
  accountNumber?: string;

  /**  */
  routingNumber?: string;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  notificationsEnabled?: boolean;

  /**  */
  contactInformation?: ContactInformationDto[];

  /**  */
  profilePhotoGuid?: string;

  /**  */
  userRoles?: number[];

  /**  */
  stateId?: number;

  /**  */
  stateName?: string;

  /**  */
  serviceAreas?: number[];

  /**  */
  vendorId?: number;

  /**  */
  recipientId?: number;

  /**  */
  isLockedOut?: boolean;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserGetEntityChangeLogsDataTableRequest {
  /**  */
  id?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  isLockedOut?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserNotificationSettingsDto {
  /**  */
  id?: number;

  /**  */
  notificationsEnabled?: boolean;
}

export interface ValidateBenefitTransactionCommand {
  /**  */
  vendorId?: number;

  /**  */
  code?: string;

  /**  */
  amount?: number;

  /**  */
  numberOfVouchersToRedeem?: number;
}

export interface ValidateTransactionResponseDto {
  /**  */
  code?: string;

  /**  */
  amount?: number;

  /**  */
  balance?: number;

  /**  */
  balanceAfter?: number;
}

export interface ValidateTransactionResponseDtoApiResult {
  /**  */
  result?: ValidateTransactionResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface VendorAchTransactionListItemDto {
  /**  */
  id?: number;

  /**  */
  occurred?: string;

  /**  */
  amount?: number;

  /**  */
  referenceNumber?: string;
}

export interface VendorAchTransactionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: VendorAchTransactionListItemDto[];
}

export interface VendorAchTransactionListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorAchTransactionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  vendorNumber?: string;

  /**  */
  accountType?: string;

  /**  */
  routingNumber?: string;

  /**  */
  accountNumber?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  isArchived?: boolean;
}

export interface VendorDetailsDtoApiResult {
  /**  */
  result?: VendorDetailsDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorDetailsDtoListApiResult {
  /**  */
  result?: VendorDetailsDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorEditDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  vendorNumber?: string;

  /**  */
  accountType?: string;

  /**  */
  routingNumber?: string;

  /**  */
  accountNumber?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  isArchived?: boolean;
}

export interface VendorEditDtoApiResult {
  /**  */
  result?: VendorEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  vendorNumber?: string[];

  /**  */
  readyToProcessTransactions?: boolean;

  /**  */
  pending?: number;

  /**  */
  isArchived?: boolean;
}

export interface VendorListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: VendorListItemDto[];
}

export interface VendorListItemDtoDataTablePageApiResult {
  /**  */
  result?: VendorListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorReportDto {
  /**  */
  numberOfAuthorizedVendors?: number;

  /**  */
  vendorList?: VendorReportListItemDto[];
}

export interface VendorReportDtoApiResult {
  /**  */
  result?: VendorReportDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VendorReportListItemDto {
  /**  */
  vendorName?: string;

  /**  */
  totalValueOfBenefits?: string;
}

export interface VendorReportQuery {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface VendorUserEditDto {
  /**  */
  id?: number;

  /**  */
  vendorId?: number;

  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;
}

export interface VendorUserEditDtoApiResult {
  /**  */
  result?: VendorUserEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface VoidAchTransactionCommand {
  /**  */
  achTransactionId?: number;
}

export interface VoucherTransactionResponseDto {
  /**  */
  status?: string;

  /**  */
  statusMessage?: string;

  /**  */
  transactions?: TransactionResponseDto[];
}

export interface VoucherTransactionResponseDtoApiResult {
  /**  */
  result?: VoucherTransactionResponseDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface WicFmnpReportDto {
  /**  */
  numberOfPregnant?: number;

  /**  */
  numberOfBreastfeeding?: number;

  /**  */
  numberOfPostpartum?: number;

  /**  */
  numberOfInfants?: number;

  /**  */
  numberOfChildren?: number;

  /**  */
  numberOfHouseholds?: number;

  /**  */
  totalParticipants?: number;

  /**  */
  benefitsIssuedAmount?: string;

  /**  */
  benefitsRedeemedAmount?: string;

  /**  */
  numberOfTransactions?: number;
}

export interface WicFmnpReportDtoApiResult {
  /**  */
  result?: WicFmnpReportDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export enum ChangeLogAction {
  'Unknown' = 'Unknown',
  'Deleted' = 'Deleted',
  'Modified' = 'Modified',
  'Added' = 'Added'
}

export enum DashboardDataType {
  'UserCount' = 'UserCount',
  'UserDetails' = 'UserDetails',
  'UserRoles' = 'UserRoles',
  'IssuancePeriod' = 'IssuancePeriod',
  'VendorSummary' = 'VendorSummary'
}

export enum FilterType {
  'Search' = 'Search',
  'EqualTo' = 'EqualTo',
  'NotEqualTo' = 'NotEqualTo',
  'StartsWith' = 'StartsWith',
  'EndsWith' = 'EndsWith',
  'LessThan' = 'LessThan',
  'LessThanEqualTo' = 'LessThanEqualTo',
  'GreaterThan' = 'GreaterThan',
  'GreaterThanEqualTo' = 'GreaterThanEqualTo',
  'Select' = 'Select',
  'MultiSelect' = 'MultiSelect',
  'Between' = 'Between',
  'NullOrEmpty' = 'NullOrEmpty',
  'NotNullOrEmpty' = 'NotNullOrEmpty'
}

export enum ReportDataSets {
  'UserStoryReportDataSet' = 'UserStoryReportDataSet',
  'RecipientReportDataSet' = 'RecipientReportDataSet'
}

import './login.css';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  faEnvelope,
  faLock,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Form,
  InjectedFieldProps,
  StringInputGroup,
  StringInputGroupProps,
  SubmitFormButton,
  validators,
} from 'envoc-form';
import { AccountService, PasswordLoginCommand } from 'api';
import {
  LinkBlue,
  Logo,
  SingleActionPage,
  SupportText,
} from 'shared/components';
import { useLoginRedirect } from 'shared/hooks';
import {
  authActions,
  authSelectors,
  authTicketStates,
  useReduxDispatch,
  useReduxSelector,
} from 'shared/state';

export default function Login() {
  const dispatch = useReduxDispatch();
  const { restoreFromLogin } = useLoginRedirect();
  const [loginFailed, setLoginFailed] = useState(false);

  const { authTicketState, user } = useReduxSelector(authSelectors.getAuth);

  useEffect(() => {
    dispatch(authActions.allowRedirects());
  }, [dispatch]);

  useEffect(() => {
    if (user && authTicketState === authTicketStates.userAuthTicketChecked) {
      restoreFromLogin();
    }
  });

  return (
    <SingleActionPage className="login">
      <Form
        onSubmit={handleSubmit}
        ignoreLostChanges={true}
        className="sm:w-96">
        {({ Field }) => (
          <>
            <Logo sizeClass="h-48" />
            <h1 className="mt-2 text-3xl text-gray-900">Login</h1>
            <SupportText className="mt-1">Sign In to your account</SupportText>
            <Field
              id="username"
              name="username"
              label="Username"
              autoComplete="username"
              Component={IconInputGroupWrapper}
              validate={validators.required}
              icon={faEnvelope}
              className="mt-6"
            />
            {loginFailed && (
              <label className="text-sm text-red-500">Login failed</label>
            )}
            <Field
              id="password"
              name="password"
              type="password"
              label="Password"
              autoComplete="current-password"
              Component={IconInputGroupWrapper}
              validate={validators.required}
              icon={faLock}
              className="mt-4"
            />
            <SubmitFormButton
              className="w-full mt-8"
              allowPristineSubmit={true}>
              Login
            </SubmitFormButton>
            <LinkBlue className="block mt-12" to="/forgot-password">
              Forgot your password? Click here
            </LinkBlue>
          </>
        )}
      </Form>
    </SingleActionPage>
  );

  function handleSubmit(values: PasswordLoginCommand) {
    return AccountService.login({ body: values }).then((resp) => {
      const signInResult = resp.result;
      if (signInResult) {
        dispatch(authActions.loadUser());
        setLoginFailed(false);
      } else {
        setLoginFailed(true);
      }
      return resp;
    });
  }
}

interface IconInputGroupWrapperProps
  extends InjectedFieldProps<string | undefined>,
    StringInputGroupProps {
  icon: IconDefinition;
  className?: string;
}

function IconInputGroupWrapper({
  icon,
  type,
  autoComplete,
  label,
  className,
  ...props
}: IconInputGroupWrapperProps) {
  return (
    <div className={className}>
      <div className="relative mt-1 group">
        <div className="absolute flex h-10 pointer-events-none w-9">
          <FontAwesomeIcon
            icon={icon}
            className={classNames('!w-5 !h-5 m-auto mt-8 text-gray-400', {
              'text-red-300 group-focus-within:text-red-500': props.meta.error,
              'group-focus-within:text-indigo-500': !props.meta.error,
            })}
          />
        </div>
        <StringInputGroup
          {...props}
          type={type}
          autoComplete={autoComplete}
          label={label}
        />
      </div>
    </div>
  );
}
